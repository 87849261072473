



































































import Vue from 'vue'
import Component, { mixins } from 'vue-class-component'
import Loader from '@/components/Global/Loader.vue'
// import { ContentLoader } from 'vue-content-loader'

import { SubscriptionApi } from '@/api'
import { State } from '@/mixins/state'
import { User } from '@/types'

import Content from '@/components/Content.vue'
import Logo from '@/components/Layout/Logo.vue'
import ListItem from '@/components/Layout/ListItem.vue'
import GroupList from '@/components/Layout/GroupList.vue'

@Component({
  components: {
    Content,
    Logo,
    ListItem,
    Loader,
    GroupList
  }
})
export default class Users extends mixins(State) {
  public users: User[] = [];

  public usersGroupedByConcernCode () {
    const distinctConcernInfo = Array.from(new Set(this.users.map(e => e.projectInformation?.concernLoginCode))).map(concernCode => {
      return {
        code: concernCode,
        name: this.users.find(userInfo => userInfo.projectInformation?.concernLoginCode === concernCode)?.projectInformation?.name
      }
    })

    const subscriptionInformationGroupedByConcernCode = distinctConcernInfo.map(concernInfo => {
      return {
        concernCode: concernInfo.code as string,
        concernName: concernInfo.name as string,
        concernLink: this.users.filter(e => e.projectInformation && e.projectInformation.concernLoginCode === concernInfo.code).find(() => true)?.projectInformation?.registrationFlowLoginUri as string | null,
        items: this.users.filter(e => e.projectInformation && e.projectInformation.concernLoginCode === concernInfo.code) as User[]
      }
    })

    return subscriptionInformationGroupedByConcernCode
  }

  public mounted () {
    this.getUsers()
  }

  public async getUsers () {
    this.$store.commit('setCurrentUser', null)
    this.state = this.states.LOADING
    this.users = []
    const api = new SubscriptionApi()
    try {
      const { data } = await api.subscriptionGetSubscriptionOverview()
      if (data.registrations) {
        data.registrations.forEach(item => {
          this.users.push({ registration: item })
        })
      }
      if (data.subscriptions) {
        data.subscriptions.forEach(item => {
          this.users.push({ subscription: item })
        })
      }

      if (this.users.length === 0) {
        this.state = this.states.LOADED
        return
      }

      await Promise.all(
        this.users.map(async user => {
          const api = new SubscriptionApi()
          if (user.registration) {
            const { data } = await api.subscriptionGetRegistration(user.registration.registrationId)
            Vue.set(user, 'employeeDetails', data.employeeDetails)
            Vue.set(user, 'projectInformation', data.projectInformation)
            Vue.set(user, 'subscriptionDetails', data.subscriptionDetails)
          } else if (user.subscription) {
            const { data } = await api.subscriptionGetSubscription(user.subscription.subscriptionId)
            Vue.set(user, 'employeeDetails', data.employeeDetails)
            Vue.set(user, 'projectInformation', data.projectInformation)
            Vue.set(user, 'subscriptionDetails', data.subscriptionDetails)
          }
        })
      )

      this.state = this.states.LOADED

      this.$store.commit('setMultipleUsers', this.users.length > 1)
      if (this.$route.meta) {
        this.$route.meta.skipTransition = false
      }
    } catch {
      this.state = this.states.ERROR
    }
  }

  setCurrentUser (user: User) {
    this.$store.commit('setCurrentUser', user)
    this.$router.push({ name: 'dashboard' })
  }

  getListItemClass (user: User) {
    if (user.registration?.subscriptionStatus === 'Pending') {
      return 'listitem-pending'
    } else if (user.registration?.subscriptionStatus === 'Accepted' || user.subscription?.subscriptionStatus === 'Accepted') {
      return 'listitem-accepted'
    } else if (user.registration?.subscriptionStatus === 'Declined') {
      return 'listitem-declined'
    } else if (user.subscription?.subscriptionStatus === 'Active') {
      return 'listitem-active'
    } else if (user.subscription?.subscriptionStatus === 'Ending') {
      return 'listitem-ending'
    } else if (user.subscription?.subscriptionStatus === 'Suspended') {
      return 'listitem-suspended'
    } else if (user.subscription?.subscriptionStatus === 'Ended') {
      return 'listitem-ended'
    } else if (user.subscription?.subscriptionStatus === 'Cancelled') {
      return 'listitem-cancelled'
    } else if (user.subscription?.subscriptionStatus === 'NeedsSigning') {
      return 'listitem-needs-signing'
    }
  }

  getStatusTextBackgroundClass (user: User) {
    const classes: string[] = []
    classes.push('subscription__status')

    return classes.join(' ')
  }

  getSubscriptionStatus (user: User) {
    if (user.registration?.subscriptionStatus === 'Pending') {
      return 'Ter beoordeling bij werkgever'
    } else if (user.registration?.subscriptionStatus === 'Accepted' || user.subscription?.subscriptionStatus === 'Accepted') {
      return 'Aanvraag wordt verwerkt'
    } else if (user.registration?.subscriptionStatus === 'Declined') {
      return 'Afgewezen'
    } else if (user.subscription?.subscriptionStatus === 'Active') {
      return 'Actief'
    } else if (user.subscription?.subscriptionStatus === 'Ending') {
      return 'Actief'
    } else if (user.subscription?.subscriptionStatus === 'Suspended') {
      return 'Opgeschort'
    } else if (user.subscription?.subscriptionStatus === 'Ended') {
      return 'Beëindigd'
    } else if (user.subscription?.subscriptionStatus === 'Cancelled') {
      return 'Geannuleerd'
    } else if (user.subscription?.subscriptionStatus === 'NeedsSigning') {
      return 'Onderteken Welkomstformulier'
    }
  }

  getEndDate (user: User) {
    if (user.subscription?.subscriptionStatus === 'Ending') {
      if (user.subscription?.endDate) {
        return 'Opgezegd per ' + this.getSubscriptionEndDate(user.subscription?.endDate)
      }
    }
  }

  getSubscriptionEndDate (endDate: string) {
    const date = new Date(endDate || '')
    return date.toLocaleDateString('nl-NL', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    })
  }

  navigateExtern (url: string) {
    window.open(url, '_blank')
  }

  logout () {
    this.$router.push({
      path: '/auth/logout'
    })
  }
}
